const isObject = (item) =>
  item && typeof item === 'object' && !Array.isArray(item);

const mergeDeep = (target, source) => {
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }
  return target;
};

const generateHighlight = (description, matchedSubstrings) => {
  let highlight = description;

  if (matchedSubstrings) {
    for (let j = matchedSubstrings.length - 1; j >= 0; j--) {
      const match = matchedSubstrings[j];
      highlight = `${highlight.substring(
        0,
        match.offset
      )}<mark>${highlight.substring(
        match.offset,
        match.offset + match.length
      )}</mark>${highlight.substring(match.offset + match.length)}`;
    }
  }
  return highlight;
};

const useCallback = (api) => (...args) => {
  const callback = args.length > 0 ? args[args.length - 1] : null;
  if (callback && typeof callback === 'function') {
    args.splice(-1, 1);
    api(...args)
      .then((result) => {
        callback(null, result);
      })
      .catch((error) => {
        callback(error);
      });
    return callback;
  }
  return api(...args);
};

const debounce = (f, interval) => {
  let timer = null;
  return (...args) => {
    if (interval === 0) {
      return f(...args);
    }
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(f(...args)), interval);
    });
  };
};

export { debounce, useCallback, mergeDeep, generateHighlight };
