import WoosmapError from '../WoosmapError';
import { ADDRESS_COMPONENTS } from '../constants';

/* global XMLHttpRequest */
export default class AbstractProvider {
  static lastXhr = null;

  static lastUrl = {};

  static lastResponse = {};

  constructor(options = {}) {
    this.defaultOptions = options;
    if (this.constructor === AbstractProvider) {
      throw new TypeError(
        'Abstract class "AbstractProvider" cannot be instantiated directly.'
      );
    }
  }

  static reset() {
    AbstractProvider.lastXhr = null;
    AbstractProvider.lastUrl = {};
    AbstractProvider.lastResponse = {};
  }

  getQueryParams(paramsMap = {}, isDeepLevel) {
    const params = isDeepLevel ? paramsMap : this.adaptParams(paramsMap);
    return Object.entries(params)
      .filter(([, value]) => value !== undefined && value !== null)
      .map(([key, value]) => {
        if (Array.isArray(value) && isDeepLevel) {
          return value
            .map(
              (arrayEntry) =>
                `${encodeURIComponent(key)}:${encodeURIComponent(arrayEntry)}`
            )
            .join('|');
        }
        if (typeof value === 'object' && !Array.isArray(value)) {
          return `${encodeURIComponent(key)}=${this.getQueryParams(
            value,
            true
          )}`;
        }

        const cleanedVal = Array.isArray(value)
          ? value
              .map((arrayEntry) => `${encodeURIComponent(arrayEntry)}`)
              .join('|')
          : encodeURIComponent(value);

        return `${encodeURIComponent(key)}${
          isDeepLevel ? encodeURIComponent(':') : '='
        }${cleanedVal}`;
      })
      .join('&');
  }

  // eslint-disable-next-line class-methods-use-this
  adaptParams(params) {
    if (!params || !params.key) {
      throw new WoosmapError('The API key is not defined', 'Missing params');
    }
    return params;
  }

  fetch(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const m = url ? url.match(/(^[^?]+)/) : [];
      const apiUrl = m && m.length > 0 ? m[0] : '';

      if (
        url &&
        AbstractProvider.lastUrl[apiUrl] &&
        AbstractProvider.lastResponse[apiUrl] &&
        url === AbstractProvider.lastUrl[apiUrl]
      ) {
        resolve(AbstractProvider.lastResponse[apiUrl]);
        return;
      }

      if (apiUrl) {
        if (
          AbstractProvider.lastXhr &&
          AbstractProvider.lastXhr.readyState !== 4
        ) {
          AbstractProvider.lastXhr.abort();
        }
        AbstractProvider.lastXhr = xhr;
      }
      xhr.open('GET', url);
      xhr.onload = () => {
        const { responseText } = xhr;
        let responseJson = null;
        let errorDetail = null;
        try {
          responseJson = JSON.parse(responseText);
        } catch (e) {
          errorDetail = 'Could not parse JSON response';
        }
        errorDetail =
          responseJson && responseJson.detail ? responseJson.detail : '';
        if (xhr.status !== 200) {
          return reject(
            new WoosmapError(errorDetail, xhr.statusDetail, xhr.status)
          );
        }
        try {
          const adaptedResponse = this.adaptResponse(responseJson);
          AbstractProvider.lastUrl[apiUrl] = url;
          AbstractProvider.lastResponse[apiUrl] = adaptedResponse;
          return resolve(adaptedResponse);
        } catch (e) {
          return reject(e);
        }
      };
      xhr.onerror = () => {
        reject(new WoosmapError('Unknown error'));
      };
      xhr.send();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  adaptResponse(response) {
    return response;
  }

  autocomplete(input) {
    if (!this.autocompleteUrl) {
      throw new WoosmapError('Provider must define attribute autocompleteUrl.');
    }
    // Return empty result if input length is too short
    if (
      this.defaultOptions.minInputLength &&
      input &&
      input.length < this.defaultOptions.minInputLength
    ) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return this.fetch(
      `${this.autocompleteUrl}${this.getQueryParams({
        input,
        key: this.defaultOptions.key,
        ...this.defaultOptions.params,
      })}`
    );
  }

  detailsFilterAddressComponents = (result) => {
    const components = [];
    (result.address_components || []).forEach((addressComponent) => {
      if (
        addressComponent.types &&
        addressComponent.types.find(
          (type) => ADDRESS_COMPONENTS.indexOf(type) !== -1
        )
      ) {
        if (addressComponent.types[0] === 'postal_codes') {
          // eslint-disable-next-line no-param-reassign
          addressComponent.types[0] = 'postal_code';
        }
        components.push(addressComponent);
      }
    });
    return components;
  };

  details(id) {
    if (!this.detailsUrl) {
      throw new WoosmapError('Provider must define attribute detailsUrl.');
    }
    return this.fetch(
      `${this.detailsUrl}${this.getQueryParams({
        id,
        key: this.defaultOptions.key,
        ...this.defaultOptions.params,
      })}`
    );
  }
}
