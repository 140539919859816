import LocalitiesProvider from './providers/LocalitiesProvider';
import AddressProvider from './providers/AddressProvider';
import StoreProvider from './providers/StoreProvider';
import PlacesProvider from './providers/PlacesProvider';
import MultiProvider from './providers/MultiProvider';
import { debounce, useCallback, mergeDeep } from './utils';

/**
 * @typedef {Object} MultiApiOptions
 * @property {number} [debounceTime=100] - The amount of time in ms the autocomplete function will wait after the last received call before executing the next one.
 * @property {ApiOptions} [localities] - Options for the Woosmap Localities API.
 * @property {ApiOptions} [address] - Options for the Woosmap Address API.
 * @property {ApiOptions} [store] - Options for the Woosmap Store API.
 * @property {ApiOptions} [places] - Options for the Google Places API.
 */

/**
 * @typedef {Object} ApiOptions
 * @property {string} key - Authentication key to be able to call the API.
 * @property {(number|false)} [fallbackBreakpoint] - Either `false` to prevent the API from being fallbacked or a number between 0 and 1 corresponding to the threashold of fallback.
 * A value of `0` indicates that the results must contain at least one perfect match while `1` will match anything. If no value is specified, default values are applied for each API (1 for store, 0,4 for localities, 0,5 for address, 1 for places)
 * @property {number} [minInputLength] - Empty result will be sent by the API and no fallback will be triggered if the input length does not reach the minInputLength value..
 * @property {Object} params - List of the API params to send.
 * Checkout:
 * [Woosmap Localities](https://developers.woosmap.com/products/localities/localities-autocomplete/#optional-parameters)
 * [Woosmap Address](https://developers.woosmap.com/products/address-api/autocomplete/#optional-parameters)
 * [Woosmap Store](https://developers.woosmap.com/products/search-api/search-query/#fields)
 * [Google Places](https://developers.google.com/maps/documentation/places/web-service/autocomplete?hl=id#place_autocomplete_requests) (Nb. The Places `language` parameter can be defined only at initialisation of the library)
 */

/**
 * @typedef {Object} AutocompleteResponseItem
 * @property {string} id - Item identifier (for `address` API, this is an internal identifier of the library.)
 * @property {('localities'|'address'|'store'|'places')} api - The api the result was retrieved from
 * @property {string} description - Contains the human-readable name for the returned result.
 * @property {array} matched_substrings - Contains an array with offset value and length. These describe the location of the entered term in the prediction result text, so that the term can be highlighted if desired.
 * @property {string} highlight - HTML description in which the entered term in the prediction result text are in `<mark>`tags
 * @property {array} types - Array of types that apply to this item.
 * @property {object} item - The item returned by the API "as is".
 */

/**
 * @typedef {Object} DetailsResponseItem
 * @property {string} id - Item identifier (for `address` API, this is an internal identifier of the library.)
 * @property {string} name - Item name
 * @property {object} geometry - Item geometry (`{location:{lat, lng}}`)
 * @property {string} formatted_address - String containing the human-readable address of this item.
 * @property {array} types - Array of feature types describing the given item (like `locality` or `postal_town`)
 * @property {array} address_components - Array containing the separate components applicable to this address. Each component has a long name (full text description or name of the address component), a short name (abbreviated textual name for the address component, if available) and types (array indicating the type of the address component which can be `locality`, `street_number`, `country`, `route` or `postal_code`)
 * @property {object} item - Item returned by the API "as is".
 */

/**
 * Create an instance of the Multisearch library.
 * @public
 * @param {MultiApiOptions} options - options of the Multisearch library
 * @return {Object} multisearchInstance Instance of the Multisearch library
 * @return {autocompleteLocalities} multisearchInstance.autocompleteLocalities - autocomplete Woosmap Localities API
 * @return {detailsLocalities} multisearchInstance.detailsLocalities - details Woosmap Localities API
 * @return {autocompleteAddress} multisearchInstance.autocompleteAddress - autocomplete Woosmap Address API
 * @return {detailsAddress} multisearchInstance.detailsAddress - details Woosmap address API
 * @return {autocompleteStore} multisearchInstance.autocompleteStore - autocomplete Woosmap Store API
 * @return {detailsStore} multisearchInstance.detailsStore - search Woosmap Store API
 * @return {autocompletePlaces} multisearchInstance.autocompletePlaces - autocomplete Google Places API
 * @return {detailsPlaces} multisearchInstance.detailsPlaces - details Google Places API
 * @return {autocompleteMulti} multisearchInstance.autocompleteMulti - smart autocomplete combination of the different APIs
 * @return {detailsMulti} multisearchInstance.detailsMulti - details of an item of one of the APIs
 */
const multisearch = (defaultSearchOptions = {}) => {
  let exportedFunctions = {};

  const encapsulate = (Provider, method) =>
    useCallback(
      debounce((param, options = {}) => {
        const { apiName } = Provider;
        const defaultOptions =
          apiName === 'multi'
            ? defaultSearchOptions
            : defaultSearchOptions[apiName];
        const mergedOptions = mergeDeep(options, defaultOptions);
        const provider = new Provider(mergedOptions);
        const cleanedParam =
          param && typeof param === 'string' ? param.trim() : param;
        return provider[method](cleanedParam);
      }, defaultSearchOptions.debounceTime || 100)
    );

  /**
   * @typedef {Function} autocompleteLocalities
   * Query the autocomplete Woosmap Localities API.
   * Check out the API [documentation](https://developers.woosmap.com/products/localities/search-city-postcode/)
   * @param {string} input - the input to autocomplete on
   * @param {ApiOptions} [options] - Options of the API
   * @param {Function} [callback] - `function(error, results)` for response callback
   * @return {Promise<AutocompleteResponseItem[]>} Promise object to get the response if no callback is specified
   */
  const autocompleteLocalities = encapsulate(
    LocalitiesProvider,
    'autocomplete'
  );

  /**
   * @typedef {Function} detailsLocalities
   * Query the details Woosmap Details API.
   * Check out the API [documentation](https://developers.woosmap.com/products/localities/localities-details/)
   * @global
   * @param {string} id - the item id to retrieve details from
   * @param {Function} [callback] - `function(error, results)` for response callback
   * @return {Promise<DetailsResponseItem>} Promise object to get the response if no callback is specified
   */
  const detailsLocalities = encapsulate(LocalitiesProvider, 'details');

  /**
   * @typedef {Function} autocompleteAddress
   * Query the autocomplete Woosmap Address API.
   * Check out the API [documentation](https://developers.woosmap.com/products/address-api/autocomplete/)
   * @param {string} input - the input to autocomplete on
   * @param {ApiOptions} [options] - Options of the API
   * @param {Function} [callback] - `function(error, results)` for response callback
   * @return {Promise<AutocompleteResponseItem[]>} Promise object to get the response if no callback is specified
   */
  const autocompleteAddress = encapsulate(AddressProvider, 'autocomplete');

  /**
   * @typedef {Function} detailsAddress
   * Query the details Woosmap Address API to get details of an address.
   * Check out the API [documentation](https://developers.woosmap.com/products/address-api/details/)
   * @param {string} id - the item id provided by the autocompleteXXX function to retrieve details from
   * @param {Function} [callback] - `function(error, results)` for response callback
   * @return {Promise<DetailsResponseItem>} Promise object to get the response if no callback is specified
   */
  const detailsAddress = encapsulate(AddressProvider, 'details');

  /**
   * @typedef {Function} autocompleteStore
   * Query the autocomplete Woosmap Store API.
   * @param {string} input - the input to autocomplete on
   * @param {ApiOptions} [options] - Options of the API
   * @param {Function} [callback] - `function(error, results)` for response callback
   * @return {Promise<AutocompleteResponseItem[]>} Promise object to get the response if no callback is specified
   */
  const autocompleteStore = encapsulate(StoreProvider, 'autocomplete');

  /**
   * @typedef {Function} detailsStore
   * Query the search Woosmap Store API to get details of a store.
   * Check out the API [documentation](https://developers.woosmap.com/products/search-api/search-query/)
   * @global
   * @param {string} id - the store id to retrieve details from
   * @param {Function} [callback] - `function(error, results)` for response callback
   * @return {Promise<DetailsResponseItem>} Promise object to get the response if no callback is specified
   */
  const detailsStore = encapsulate(StoreProvider, 'details');

  /**
   * @typedef {Function} autocompletePlaces
   * Query the autocomplete Google Places API.
   * Check out the API [documentation](https://developers.google.com/maps/documentation/places/web-service/autocomplete)
   * @param {string} input - the input to autocomplete on
   * @param {ApiOptions} [options] - Options of the API
   * @param {Function} [callback] - `function(error, results)` for response callback
   * @return {Promise<AutocompleteResponseItem[]>} Promise object to get the response if no callback is specified
   */
  const autocompletePlaces = encapsulate(PlacesProvider, 'autocomplete');

  /**
   * @typedef {Function} detailsPlaces
   * Query the details Google Places API to get details of a place.
   * Check out the API [documentation](https://developers.google.com/maps/documentation/places/web-service/details)
   * @param {string} id - the place id to retrieve details from
   * @param {Function} [callback] - `function(error, results)` for response callback
   * @return {Promise<DetailsResponseItem>} Promise object to get the response if no callback is specified
   */
  const detailsPlaces = encapsulate(PlacesProvider, 'details');

  /**
   * @typedef {Function} autocompleteMulti
   * Query the different autocomplete APIs defined in the `apiOrder` list.
   * Depending on the `fallbackBreakpoint` and the `minInputLength` defined for each API, if the first API does not send revelant enough results, it will query the next one, until results are relevant or no other API is in the list.
   * @param {string} input - the input to autocomplete on
   * @param {MultiApiOptions} [options] - Options of the API
   * @param {Function} [callback] - `function(error, results)` for response callback
   * @return {Promise<AutocompleteResponseItem[]>} Promise object to get the response if no callback is specified
   */
  const autocompleteMulti = encapsulate(MultiProvider, 'autocomplete');

  /**
   * @typedef {Function} detailsMulti
   * Query the details api to get details of an item.
   * @param {string} id - the id of the item to retrieve details from
   * @param {('localities'|'address'|'store'|'places')} api - the api to retrieve details from
   * @param {Function} [callback] - `function(error, results)` for response callback
   * @return {Promise<DetailsResponseItem>} Promise object to get the response if no callback is specified
   */
  const detailsMulti = encapsulate(MultiProvider, 'details');

  exportedFunctions = {
    ...exportedFunctions,
    autocompleteLocalities,
    detailsLocalities,
    autocompleteAddress,
    detailsAddress,
    autocompleteStore,
    detailsStore,
    autocompletePlaces,
    detailsPlaces,
    autocompleteMulti,
    detailsMulti,
  };

  return exportedFunctions;
};

export default multisearch;
