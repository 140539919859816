/* eslint-disable class-methods-use-this */
import AbstractProvider from './AbstractProvider';
import {
  URL_STORE_DETAILS_API,
  URL_STORE_AUTOCOMPLETE_API,
} from '../constants';
import { generateHighlight } from '../utils';

export default class StoreProvider extends AbstractProvider {
  static apiName = 'store';

  constructor(options) {
    super(options);
    this.autocompleteUrl = URL_STORE_AUTOCOMPLETE_API;
    this.detailsUrl = URL_STORE_DETAILS_API;
  }

  adaptParams(params) {
    super.adaptParams(params);
    const { input, query, id } = params;
    let queryWithInput = query || '';
    const newParams = { ...params };
    delete newParams.input;
    delete newParams.id;
    if (input) {
      queryWithInput += `${query ? '|' : ''}localized:"${input}"`;
    }
    if (id) {
      queryWithInput += `${query ? '|' : ''}idstore:"${id}"`;
    }
    newParams.query = queryWithInput;
    return newParams;
  }

  adaptResponse(responseJson) {
    let result = [];
    if (responseJson.predictions) {
      result = responseJson.predictions.map((item) => {
        // eslint-disable-next-line camelcase
        const { name, matched_substrings, types, store_id } = item;
        return {
          api: 'store',
          description: name,
          matched_substrings,
          types,
          id: store_id,
          highlight: generateHighlight(name, matched_substrings),
          item,
        };
      });
    } else if (responseJson.features && responseJson.features.length > 0) {
      const feature = responseJson.features[0];
      result = { ...feature.properties };
      result.formatted_address = '';
      if (result.address && result.address.lines) {
        result.address.lines.forEach((line) => {
          result.formatted_address += `${line} `;
        });
        result.formatted_address = result.formatted_address.trim();
      }
      result.geometry = {
        location:
          feature.geometry && feature.geometry.coordinates
            ? {
                lat: feature.geometry.coordinates[1],
                lng: feature.geometry.coordinates[0],
              }
            : null,
      };
      result.address_components = this.detailsFilterAddressComponents(
        feature.properties
      );
      result.types = feature.properties.types;
      result.id = feature.properties.store_id;
      result.name = feature.properties.name;
      result.item = feature;
    }
    return result;
  }
}
