/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import AbstractProvider from './AbstractProvider';
import WoosmapError from '../WoosmapError';
import {
  URL_ADDRESS_AUTOCOMPLETE_API,
  URL_ADDRESS_DETAILS_API,
  ERROR_ADDRESS_REQUEST_DENIED,
  ERROR_ADDRESS_INVALID_REQUEST,
  ERROR_ADDRESS_UNKNOWN_ERROR,
  ADDRESS_OK_STATUS,
  ADDRESS_OK_ZERO_RESULTS,
} from '../constants';
import { generateHighlight } from '../utils';

export default class AddressProvider extends AbstractProvider {
  static apiName = 'address';

  constructor(options) {
    super(options);
    this.autocompleteUrl = URL_ADDRESS_AUTOCOMPLETE_API;
    this.detailsUrl = URL_ADDRESS_DETAILS_API;
  }

  adaptParams(params) {
    super.adaptParams(params);
    const newParams = { ...params };
    const { id } = params;
    delete newParams.id;

    if (id) {
      newParams.public_id = id;
    }
    newParams.cc_format = 'alpha2';
    return newParams;
  }

  adaptResponse(responseJson) {
    let result = [];
    if (
      responseJson.status !== ADDRESS_OK_STATUS &&
      responseJson.status !== ADDRESS_OK_ZERO_RESULTS
    ) {
      switch (responseJson.status) {
        case ERROR_ADDRESS_REQUEST_DENIED:
          throw new WoosmapError(
            responseJson.error_message,
            'Unauthorized',
            401
          );
        case ERROR_ADDRESS_INVALID_REQUEST:
        case ERROR_ADDRESS_UNKNOWN_ERROR:
        default:
          throw new WoosmapError(
            responseJson.error_message,
            'Bad request',
            400
          );
      }
    }
    if (responseJson.predictions) {
      result = responseJson.predictions.map((item) => {
        const { description, matched_substring, type, public_id } = item;
        return {
          api: 'address',
          description,
          matched_substrings: matched_substring
            ? matched_substring.description
            : null,
          types: [type],
          id: public_id,
          highlight: generateHighlight(
            description,
            matched_substring ? matched_substring.description : null
          ),
          item,
        };
      });
    } else if (responseJson.result) {
      const item = responseJson.result;
      const location = item.geometry ? item.geometry.location || {} : {};
      result = {
        formatted_address: item.formatted_address,
        geometry: { location: { lat: location.lat, lng: location.lng } },
        id: item.public_id,
        types: item.types,
        name: '',
        item,
        address_components: this.detailsFilterAddressComponents(item),
      };
    }
    return result;
  }
}
