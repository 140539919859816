import { GOOGLE_PLACES_API, NO_GOOGLE_API_KEY_DETECTED } from '../constants';
import AbstractProvider from './AbstractProvider';
import { generateHighlight } from '../utils';
import WoosmapError from '../WoosmapError';

export default class PlacesProvider extends AbstractProvider {
  static apiName = 'places';

  static googlePlacesService = null;

  static sessionToken = null;

  static googlePlaceDetailService = null;

  static loadingPromise = null;

  constructor(options = {}) {
    super(options);
    if (!PlacesProvider.loadingPromise) {
      PlacesProvider.loadGoogle(
        this.defaultOptions.key,
        this.defaultOptions.params
          ? this.defaultOptions.params.language
          : undefined
      );
    }
  }

  static setGooglePlacesService() {
    if (!PlacesProvider.googlePlacesService && typeof google !== 'undefined')
      PlacesProvider.googlePlacesService = new google.maps.places.AutocompleteService();
    if (
      !PlacesProvider.googlePlaceDetailService &&
      typeof google !== 'undefined'
    )
      PlacesProvider.googlePlaceDetailService = new google.maps.places.PlacesService(
        document.createElement('div')
      );
  }

  static loadGoogle(googleApiKey, language) {
    PlacesProvider.loadingPromise = new Promise((resolve, reject) => {
      if (typeof google !== 'undefined' && google.maps) {
        if (!google.maps.places) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(
            new WoosmapError(
              'Google maps already loaded but not the Places library',
              'Initialisation error'
            )
          );
          return;
        }
        resolve(PlacesProvider.setGooglePlacesService());
        return;
      }
      if (!googleApiKey) reject(NO_GOOGLE_API_KEY_DETECTED);
      if (PlacesProvider.googlePlacesService) resolve();

      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.async = true;
      script.onerror = (reason) => {
        reject(new WoosmapError(reason, 'Places loading error'));
      };

      // IE
      if (script.readyState) {
        script.onreadystatechange = () => {
          if (
            script.readyState === 'loaded' ||
            script.readyState === 'complete'
          ) {
            script.onreadystatechange = null;
            resolve(PlacesProvider.setGooglePlacesService());
          }
        };
      } else {
        script.onload = () => {
          resolve(PlacesProvider.setGooglePlacesService());
        };
      }
      const languageParam = language ? `&language=${language}` : '';
      script.src = `${GOOGLE_PLACES_API}key=${googleApiKey}${languageParam}`;
      document.head.appendChild(script);
    });
  }

  autocomplete(input) {
    if (
      this.defaultOptions.minInputLength &&
      input &&
      input.length < this.defaultOptions.minInputLength
    ) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return PlacesProvider.loadingPromise.then(
      () =>
        new Promise((resolve, reject) => {
          if (!PlacesProvider.sessionToken && typeof google !== 'undefined') {
            PlacesProvider.sessionToken = new google.maps.places.AutocompleteSessionToken();
          }
          const { params } = this.defaultOptions;
          if (params && params.components) {
            params.componentRestrictions = params.components;
            delete params.components;
          }
          PlacesProvider.googlePlacesService.getPlacePredictions(
            {
              input,
              sessionToken: PlacesProvider.sessionToken,
              ...this.defaultOptions.params,
            },
            (results, status) =>
              status === 'OK' || status === 'ZERO_RESULTS'
                ? this.adaptResponse(results, resolve)
                : reject(status)
          );
        })
    );
  }

  // eslint-disable-next-line class-methods-use-this
  adaptResponse(responseJson, resolve) {
    const result = !responseJson
      ? []
      : responseJson.map((item) => ({
          api: 'places',
          id: item.place_id,
          description: item.description,
          types: item.types,
          highlight: generateHighlight(
            item.description,
            item.matched_substrings
          ),
          item,
        }));
    resolve(result);
  }

  // eslint-disable-next-line class-methods-use-this
  details(id) {
    return PlacesProvider.loadingPromise.then(
      () =>
        new Promise((resolve, reject) => {
          PlacesProvider.googlePlaceDetailService.getDetails(
            {
              placeId: id,
              sessionToken: PlacesProvider.sessionToken,
              fields: [
                'address_component',
                'adr_address',
                'formatted_address',
                'geometry',
                'icon',
                'name',
                'place_id',
                'type',
                'url',
                'vicinity',
              ],
            },
            (item, status) => {
              const result = { formatted_address: item.formatted_address };
              result.geometry = { location: {} };
              result.geometry.location.lat = item.geometry.location.lat();
              result.geometry.location.lng = item.geometry.location.lng();
              result.name = item.name;
              result.types = item.types;
              result.id = item.place_id;
              result.address_components = this.detailsFilterAddressComponents(
                item
              );
              result.item = item;
              return status === 'OK' ? resolve(result) : reject(status);
            }
          );
        })
    );
  }
}
