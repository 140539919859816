import Fuse from 'fuse.js/dist/fuse.basic';
import WoosmapError from '../WoosmapError';
import LocalitiesProvider from './LocalitiesProvider';
import AddressProvider from './AddressProvider';
import PlacesProvider from './PlacesProvider';
import StoreProvider from './StoreProvider';

const providers = {
  localities: LocalitiesProvider,
  places: PlacesProvider,
  address: AddressProvider,
  store: StoreProvider,
};

export default class MultiProvider {
  static apiName = 'multi';

  static lastInput = '';

  static lastApiCalled = '';

  static newInputIsContainedInLastInput = false;

  constructor(options) {
    this.options = options;
    this.apisResult = {};
  }

  autocomplete(input) {
    return new Promise((resolve, reject) => {
      const apis = this.options.apiOrder ? [...this.options.apiOrder] : null;
      if (!apis || !Array.isArray(apis) || apis.length === 0) {
        return reject(
          new WoosmapError('apiOrder not properly defined', 'Missing params')
        );
      }
      try {
        const cleanedInput = input && input.length > 0 ? input.trim() : input;
        MultiProvider.newInputIsContainedInLastInput =
          MultiProvider.lastInput &&
          cleanedInput &&
          cleanedInput.indexOf(MultiProvider.lastInput) !== -1;
        MultiProvider.lastInput = cleanedInput;
        return this.autocompleteApi(input, apis, [], true)
          .then((result) => resolve(result))
          .catch((e) => reject(e));
      } catch (e) {
        return reject(e);
      }
    });
  }

  getFallbackBreakpoint(api) {
    if (this.options[api].fallbackBreakpoint === undefined) {
      switch (api) {
        case 'store':
          return 1;
        case 'localities':
          return 0.4;
        case 'address':
          return 0.5;
        case 'places':
          return 1;
        default:
          return false;
      }
    }
    return this.options[api].fallbackBreakpoint;
  }

  // eslint-disable-next-line class-methods-use-this
  autocompleteApi(input, apis, result, fallbackNeeded) {
    const api = apis.shift();
    if (!api) {
      return result;
    }
    if (!this.options[api]) {
      throw new WoosmapError(
        `Api ${api} not properly configured`,
        'Missing params'
      );
    }

    const lastApiCalledIsAfterCurrentOne =
      MultiProvider.lastApiCalled &&
      apis.indexOf(MultiProvider.lastApiCalled) !== -1;

    // Do not call api if the last call was done on another fallbacking API which is after, in the ordered list.
    if (
      this.getFallbackBreakpoint(api) &&
      MultiProvider.newInputIsContainedInLastInput &&
      lastApiCalledIsAfterCurrentOne
    ) {
      return this.autocompleteApi(input, apis, result, fallbackNeeded);
    }

    // We call the new api if either it's a fallback api and the previous loop indicated that fallbackNeeded or it's not a fallback API
    if (
      (this.getFallbackBreakpoint(api) && fallbackNeeded) ||
      this.getFallbackBreakpoint(api) === false
    ) {
      const Provider = providers[api];
      if (!Provider) {
        throw new WoosmapError(
          `Api ${api} not properly configured`,
          'Missing params'
        );
      }
      const provider = new Provider(this.options[api]);
      MultiProvider.lastApiCalled = this.getFallbackBreakpoint(api)
        ? api
        : MultiProvider.lastApiCalled;
      return provider.autocomplete(input).then((apiResult) => {
        if (this.getFallbackBreakpoint(api) === false) {
          return this.autocompleteApi(
            input,
            apis,
            [...result, ...apiResult],
            fallbackNeeded
          );
        }
        const filteredResults = this.filterResults(input, apiResult, api);
        const inputTooShort =
          this.options[api].minInputLength &&
          input &&
          input.length < this.options[api].minInputLength;
        return this.autocompleteApi(
          input,
          apis,
          [...result, ...filteredResults],
          filteredResults.length === 0 && !inputTooShort
        );
      });
    }
    // otherwise we go to next api
    return this.autocompleteApi(input, apis, result, fallbackNeeded);
  }

  filterResults(input, results, api) {
    const optionsFuzz = {
      threshold: this.getFallbackBreakpoint(api),
      includeScore: true,
      findAllMatches: true,
      ignoreLocation: true,
      ignoreFieldNorm: true,
      keys: ['description'],
    };
    const fuse = new Fuse(results, optionsFuzz);

    const filteredResults = fuse.search(input);
    return filteredResults.map((fuzed) => ({
      ...fuzed.item,
      score: fuzed.score,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  details({ id, api }) {
    return new Promise((resolve, reject) => {
      const Provider = providers[api];
      if (!Provider) {
        return reject(
          new WoosmapError('Api not properly defined', 'Missing params')
        );
      }
      if (!this.options[api]) {
        throw new WoosmapError(
          `Api ${api} not properly configured`,
          'Missing params'
        );
      }
      if (!id) {
        return reject(new WoosmapError('Id not defined', 'Missing params'));
      }
      return resolve(new Provider(this.options[api]).details(id));
    });
  }
}
