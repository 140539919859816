/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import AbstractProvider from './AbstractProvider';
import { URL_LOCALITIES_API, URL_LOCALITIES_DETAILS_API } from '../constants';
import { generateHighlight } from '../utils';

export default class LocalitiesProvider extends AbstractProvider {
  static apiName = 'localities';

  constructor(options) {
    super(options);
    this.autocompleteUrl = URL_LOCALITIES_API;
    this.detailsUrl = URL_LOCALITIES_DETAILS_API;
  }

  adaptParams(params) {
    super.adaptParams(params);
    const newParams = { ...params };
    const { id } = params;
    delete newParams.id;

    if (id) {
      newParams.public_id = id;
    }
    return newParams;
  }

  adaptResponse(responseJson) {
    let result = [];
    if (responseJson.localities) {
      result = responseJson.localities.map((item) => {
        const { description, matched_substrings, type, public_id } = item;
        return {
          api: 'localities',
          description,
          matched_substrings: matched_substrings
            ? matched_substrings.description
            : null,
          types: [type],
          id: public_id,
          highlight: generateHighlight(
            description,
            matched_substrings ? matched_substrings.description : null
          ),
          item,
        };
      });
    } else if (responseJson.result) {
      const location = responseJson.result.geometry
        ? responseJson.result.geometry.location || {}
        : {};
      result = {
        geometry: { location: { lat: location.lat, lng: location.lng } },
        formatted_address: responseJson.result.formatted_address,
        types: responseJson.result.types,
        id: responseJson.result.public_id,
        name: responseJson.result.name,
        item: responseJson.result,
        address_components: this.detailsFilterAddressComponents(
          responseJson.result
        ),
      };
    }
    return result;
  }
}
