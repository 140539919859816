// Constants
const BASE_WOOSMAP_URL = process.env.REACT_APP_WOOSMAP_API_URL;
const URL_LOCALITIES_API = `${BASE_WOOSMAP_URL}/localities/autocomplete/?`;
const URL_LOCALITIES_DETAILS_API = `${BASE_WOOSMAP_URL}/localities/details/?`;
const URL_ADDRESS_AUTOCOMPLETE_API = `${BASE_WOOSMAP_URL}/address/autocomplete/json?`;
const URL_ADDRESS_DETAILS_API = `${BASE_WOOSMAP_URL}/address/details/json?`;
const URL_STORE_DETAILS_API = `${BASE_WOOSMAP_URL}/stores/search/?`;
const URL_STORE_AUTOCOMPLETE_API = `${BASE_WOOSMAP_URL}/stores/autocomplete/?`;
const GOOGLE_PLACES_API =
  'https://maps.googleapis.com/maps/api/js?libraries=places&';

// Status
const ERROR_ADDRESS_REQUEST_DENIED = 'REQUEST_DENIED';
const ERROR_ADDRESS_INVALID_REQUEST = 'INVALID_REQUEST';
const ERROR_ADDRESS_UNKNOWN_ERROR = 'UNKNOWN_ERROR';
const ADDRESS_OK_STATUS = 'OK';
const ADDRESS_OK_ZERO_RESULTS = 'ZERO_RESULTS';

// Internal Values
const NO_GOOGLE_API_KEY_DETECTED = 'No google api key detected';
const GOOGLE_AUTH_ERROR = 'Authentication google failed';
const LOCALITIES_INDEX = 0;
const ADDRESSES_INDEX = 1;
const DEFAULT_BREAKPOINT = 0.5;

const ADDRESS_COMPONENTS = [
  'locality',
  'street_number',
  'country',
  'route',
  'postal_code',
  'postal_codes',
];

export {
  URL_LOCALITIES_API,
  URL_LOCALITIES_DETAILS_API,
  URL_ADDRESS_AUTOCOMPLETE_API,
  URL_ADDRESS_DETAILS_API,
  URL_STORE_AUTOCOMPLETE_API,
  URL_STORE_DETAILS_API,
  ERROR_ADDRESS_REQUEST_DENIED,
  ERROR_ADDRESS_INVALID_REQUEST,
  ERROR_ADDRESS_UNKNOWN_ERROR,
  ADDRESS_OK_STATUS,
  ADDRESS_OK_ZERO_RESULTS,
  GOOGLE_PLACES_API,
  NO_GOOGLE_API_KEY_DETECTED,
  LOCALITIES_INDEX,
  ADDRESSES_INDEX,
  DEFAULT_BREAKPOINT,
  GOOGLE_AUTH_ERROR,
  ADDRESS_COMPONENTS,
};
